import React, { FC, useContext } from "react";
import MaxWidth from "../styles/max-width";
import styled from "styled-components";
import FooterText from "./FooterText";
import Link from "next/link";
import { opacityHover } from "../styles/atoms";
import CitySelect from "./CitySelect";
import { GRAY_LIGHT, GRAY } from "../styles/constants";
import Social from "./Social";
import BarFooter from "./BarFooter";
import useShop from "../hooks/use-shop";
import { CityContext } from "../context/CityContext";

interface Props {
  main: boolean | undefined;
}

const Footer: FC<Props> = ({ main }) => {
  const { phone, legalInfo } = useContext(CityContext);
  const { workTimeStart, workTimeEnd, workTimeSet } = useShop();

  return (
    <FooterEl>
      <MaxWidth>
        {main && <FooterText />}
        <NavWrap main={main}>
          <Nav>
            {/* <FooterSelect /> */}
            <BarFooter />
          </Nav>
        </NavWrap>

        <ContactsWrap>
          <Contacts>
            <SocialWrap>
              <Social black />
            </SocialWrap>
            <div>
              <Phone href={phone.footer.href}>
                <span className="time">
                  {workTimeSet
                    ? `С ${workTimeStart} - 24:00`
                    : "С 8:00 - 24:00"}
                </span>
                <span className="number">{phone.footer.number}</span>
              </Phone>
              <Phone $small href={phone.header.href}>
                <span className="number">{phone.header.number}</span>
              </Phone>
            </div>
          </Contacts>
        </ContactsWrap>
        <Bottom>
          {legalInfo?.length > 0 && legalInfo?.length === 1 ? (
            <>
              <p className="info">{legalInfo}</p>
            </>
          ) : (
            <ul className="info-list">
              {legalInfo?.map((item, index) => (
                <li key={`footer_info_${index}`}>{item}</li>
              ))}
            </ul>
          )}
          <ul className="list">
            <li className="item">{new Date().getFullYear()} © Микаfood</li>
            <li className="item">
              <Link href="/privacy-policy">
                <a className="footer-link" href="/privacy-policy">
                  Политика конфиденциальности
                </a>
              </Link>
            </li>
            <li className="item">
              <a
                className="footer-link"
                href="https://kolibri.studio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Сделано в kolibri.studio
              </a>
            </li>
          </ul>
        </Bottom>
      </MaxWidth>
    </FooterEl>
  );
};

const NavItem = styled.a`
  font-size: 20px;
  line-height: 2;
  ${opacityHover};

  &:not(:first-child) {
    margin-left: 10px;
  }

  &.outside {
    &:after {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 15px;
      background: url("/static/icons/outside.svg") no-repeat center;
      vertical-align: baseline;
      content: "";
    }
  }

  @media (max-width: 1400px) {
    font-size: 19px;
  }

  @media (max-width: 1200px) {
    font-size: 18px;
  }
`;

const Bottom = styled.div`
  padding: 40px 0 50px;
  text-align: center;
  font-size: 14px;

  .info {
    margin: 0 0 20px;
    color: ${GRAY};
  }

  .list {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  .info-list {
    margin: 0 0 70px;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
      display: block;
      margin: 0 0 35px;
    }

    li {
      max-width: 16.5625rem;
      text-align: left;
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.75rem;

      @media (max-width: 800px) {
        max-width: none;
      }

      &:not(:last-child) {
        margin-right: 1rem;

        @media (max-width: 800px) {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .item {
    display: inline-block;
    line-height: 1.25;
    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  .footer-link {
    display: inline-block;
    border-bottom: 1px solid ${GRAY_LIGHT};
    ${opacityHover};
  }

  @media (max-width: 800px) {
    padding: 30px 0 40px;

    .list {
      flex-direction: column;
    }

    .item {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 0.75em;
      }
    }
  }
`;

const FooterEl = styled.footer`
  background-color: #fff;
  flex-shrink: 0;
`;

const NavWrap = styled.div`
  padding: 30px 0;
  border-top: ${({ main }: { main: boolean | undefined }) =>
    main ? `1px solid ${GRAY_LIGHT}` : "none"};
  border-bottom: 1px solid ${GRAY_LIGHT};

  @media (max-width: 980px) {
    display: none;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1240px;
  margin: 0 auto;
`;

const FooterSelect = styled(CitySelect)`
  margin-left: -12px;

  .selected:after {
    border-top-color: #000;
  }

  .selected,
  .item {
    font-size: 20px;
    line-height: 2;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: none;

    @media (max-width: 1400px) {
      font-size: 19px;
    }

    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }
`;

const ContactsWrap = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${GRAY_LIGHT};
  @media (max-width: 980px) {
    border-top: 1px solid ${GRAY_LIGHT};
  }
`;

const Contacts = styled.div`
  display: flex;
  align-items: center;
  max-width: 840px;
  margin: 0 auto;
`;

const SocialWrap = styled.div`
  flex: 1 1 100%;
  border-right: 1px solid ${GRAY_LIGHT};

  @media (max-width: 800px) {
    border-right: none;
  }
`;

const Phone = styled.a<{ $small?: boolean }>`
  display: inline-block;
  line-height: 1.3;
  padding: 0 10px;
  padding-left: 8%;
  flex: 1 1 100%;

  .time,
  .number {
    display: block;
  }

  .time {
    font-size: 14px;
    opacity: 0.5;
  }

  .number {
    font-size: ${({ $small }) => ($small ? "22px" : "32px")};
    font-weight: bold;
    white-space: nowrap;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export default Footer;
