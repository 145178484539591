import React, { FC, useContext, useState } from "react";
import styled from "styled-components";
import { YELLOW_DARK, YELLOW_LIGHT } from "../styles/constants";
import { easeIn, easeOut } from "../styles/atoms";
import { CityContext } from "../context/CityContext";
import { destroyCookie } from "nookies";

// const cities = [
//   {
//     name: "Магнитогорск",
//     code: "magnitogorsk",
//     href: "https://mikafood.ru/",
//   },
// ];

const CitySelect: FC<{}> = (props) => {
  const [open, setOpen] = useState(false);
  const { cityName } = useContext(CityContext);

  return (
    <Container {...props}>
      <Selected
        className={`selected${open ? " open" : ""}`}
        onClick={() => setOpen(!open)}
      >
        {cityName}
      </Selected>
      {/* <Drop
        className={`drop${open ? " open" : ""}`}
        onClick={() => setOpen(false)}
      >
        {cities
          .filter(({ name }) => name !== cityName)
          .map(({ name, href }) => (
            <StyledLink
              href={href}
              key={name}
              onClick={() => {
                destroyCookie({}, "checkoutId", {
                  path: "/",
                  domain: window.location.hostname,
                });
              }}
            >
              <BaseItem className="item">{name}</BaseItem>
            </StyledLink>
          ))}
      </Drop> */}
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  height: 36px;
`;

const Drop = styled.div`
  display: block;
  box-shadow: 0px 12px 22px #f0c20c;
  border-radius: 10px;
  ${easeIn};
  opacity: 0;
  visibility: hidden;

  &.open {
    ${easeOut};
    opacity: 1;
    visibility: visible;
  }
`;

const Item = styled.div`
  font-size: 12px;
  line-height: 1.16;
  padding: 12px;
  padding-right: 30px;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  ${easeIn};
`;

const BaseItem = styled(Item)`
  color: #000;
  background-color: ${YELLOW_LIGHT};

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  ${Drop}.open &:hover {
    background-color: ${YELLOW_DARK};
    ${easeOut};
  }
`;

const Selected = styled(Item)`
  position: relative;
  border-radius: 10px 10px 0 0;

  // &:after {
  //   position: absolute;
  //   right: 12px;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;
  //   display: inline-block;
  //   height: 0;
  //   width: 0;
  //   border: 5px solid transparent;
  //   border-top-color: #fff;
  //   border-bottom: 0;
  //   ${easeIn};
  //   content: "";
  // }

  // &.open {
  //   color: #000;
  //   background-color: ${YELLOW_DARK};
  //   &:after {
  //     ${easeOut};
  //     border-top-color: #000;
  //     transform: rotate(180deg);
  //   }
  // }
`;

const StyledLink = styled.a``;

export default CitySelect;
