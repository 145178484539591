import React, { FC, Dispatch, SetStateAction, useContext } from "react";
import styled, { css } from "styled-components";
import Link from "next/link";
import dynamic from "next/dynamic";

import { GRAY_LIGHT, GREEN } from "../styles/constants";
import { opacityHover, easeIn, easeOut } from "../styles/atoms";
import BarHeader from "./BarHeader";
import HeaderBasket from "./HeaderBasket";
import AuthorizationContext from "../context/Authorization";
import useShop from "../hooks/use-shop";
import CitySelect from "./CitySelect";
import { CityContext } from "../context/CityContext";

const AuthorizationForm = dynamic(import("../forms/AuthorizationForm"), {
  ssr: false,
});

interface Props {
  menuIsOpen: boolean;
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>;
}

const Header: FC<Props> = ({ menuIsOpen, setMenuIsOpen }) => {
  const { phone } = useContext(CityContext);
  const { show, setShow, avatar, isAuthorized } =
    useContext(AuthorizationContext);

  const { workTimeStart, workTimeEnd, workTimeSet } = useShop();

  return (
    <Container className={menuIsOpen ? "open" : ""}>
      <Row>
        <MenuBtn
          className={menuIsOpen ? "open" : ""}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <span className="icon">
            <span className="icon-center" />
          </span>
          <span className="text kabel">ВСЕ МЕНЮ</span>
        </MenuBtn>
        <MobilePhone>
          <MobilePhoneSeparator />
          <MobilePhoneIco href={phone.header.href} />
        </MobilePhone>
        <CitySelectWrap className={menuIsOpen ? "open" : ""}>
          <CitySelect />
        </CitySelectWrap>
        <CitySeparator />
        <BarHeader />
      </Row>
      <Link href="/">
        <Logo href="/">
          <LogoIcon src="/static/icons/logo.svg" alt="лого" />
        </Logo>
      </Link>
      <Row>
        <Phone href={phone.header.href}>
          <span className="time">
            {workTimeSet ? `С ${workTimeStart} - 24:00` : "С 8:00 - 24:00"}
          </span>
          <span className="number">{phone.header.number}</span>
        </Phone>
        <ToggleSeparator />
        {isAuthorized ? (
          <Avatar href="/profile" avatar={avatar}>
            <img
              className={"cover"}
              src={
                avatar
                  ? `${process.env.NEXT_PUBLIC_OPTIM_ENDPOINT}/160/160/70/?url=${avatar.url}`
                  : "/static/images/avatar.png"
              }
            />
          </Avatar>
        ) : (
          <Personal onClick={() => setShow(!show)} />
        )}
        <Separator />
        <HeaderBasket />
      </Row>

      <AuthorizationForm show={show} setShow={setShow} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  color: #fff;

  @media (max-width: 800px) {
    padding-right: 10px;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
    ${easeIn};

    &.open {
      box-shadow: 0px 12px 12px rgba(0, 0, 0, 0);
      ${easeOut};
    }
  }
`;

const CitySelectWrap = styled.div`
  display: inline-block;
  height: 37px;

  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 67px;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: ${GREEN};
    opacity: 0;
    visibility: hidden;
    ${easeIn};

    &.open {
      opacity: 1;
      visibility: visible;
      ${easeOut};
    }
  }
`;

const MobilePhone = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: inline-flex;
    align-items: center;
  }
`;

const MobilePhoneIco = styled.a`
  display: block;
  border: none;
  background: url("/static/icons/phone.svg") no-repeat center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  ${opacityHover};
`;

const Separator = styled.div`
  display: inline-block;
  margin: 0 10px;
  width: 1px;
  height: 36px;
  background-color: ${GRAY_LIGHT};

  &:last-child {
    display: none;
  }
`;

const MobilePhoneSeparator = styled(Separator)`
  margin-left: 0;
`;

const ToggleSeparator = styled(Separator)`
  @media (max-width: 800px) {
    display: none;
  }
`;

const MenuBtn = styled.button`
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  flex-shrink: 0;
  width: 185px;
  margin-left: -20px;
  margin-right: 10px;
  height: 60px;
  background-color: #fff;
  padding: 0 30px;
  cursor: pointer;

  .icon,
  .text {
    display: inline-block;
  }

  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    background-color: ${GREEN};
    border-radius: 50%;
    margin-right: 10px;

    &:before,
    &:after,
    .icon-center {
      display: block;
      height: 2px;
      width: 14px;
      background-color: #fff;
      transform: translate3d(0, 0, 0);
      ${easeIn};
    }

    &:before,
    &:after {
      content: "";
    }

    .icon-center {
      margin: 2px 0;
    }
  }

  @media (max-width: 800px) {
    background: none;
    padding: 0 20px;
    width: auto;
    margin-right: 0;

    .text {
      display: none;
    }

    .icon {
      margin-right: 0;

      &:before,
      &:after,
      .icon-center {
        height: 1px;
        width: 20px;
      }

      .icon-center {
        margin: 5px 0;
      }
    }

    &.open {
      .icon {
        &:before,
        &:after,
        .icon-center {
          ${easeOut};
          transform-origin: 50% 50%;
        }

        .icon-center {
          transform: translate3d(50%, 0, 0);
          opacity: 0;
        }

        &:before {
          transform: rotate(45deg) translate3d(3px, 5px, 0);
        }
        &:after {
          transform: rotate(-45deg) translate3d(3px, -5px, 0);
        }
      }
    }
  }
`;

const Logo = styled.a`
  display: inline-block;
  transform: translate3d(0, 15px, 0);

  @media (max-width: 1400px) {
    transform: none;
  }

  @media (max-width: 1200px) {
    margin-left: 50px;
  }

  @media (max-width: 980px) {
    margin-left: 0;
  }
`;

const LogoIcon = styled.img`
  display: block;
  width: 270px;
  height: auto;

  @media (max-width: 1400px) {
    width: 150px;
  }

  @media (max-width: 600px) {
    width: 115px;
  }

  @media (max-width: 360px) {
    width: 80px;
  }
`;

const Row = styled.div`
  display: inline-flex;
  align-items: center;
`;

const CitySeparator = styled(Separator)`
  @media (max-width: 980px) {
    display: none;
  }
`;

const Phone = styled.a`
  display: inline-block;
  text-align: right;
  line-height: 1.3;
  padding: 0 10px;

  .time,
  .number {
    display: block;
  }

  .time {
    font-size: 12px;
    opacity: 0.5;
  }

  .number {
    font-size: 20px;
  }

  @media (max-width: 980px) {
    .number {
      font-size: 14px;
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const Personal = styled.button`
  border: none;
  background: url("/static/icons/lock.svg") no-repeat center;
  height: 40px;
  width: 40px;
  padding: 0;
  cursor: pointer;
  ${opacityHover};

  @media (max-width: 800px) {
    background-size: 16px;
  }
`;

interface PropsAvatar {
  avatar: any;
}

const AvatarWithoutImage = css`
  width: 24px;
  height: auto;
`;

const Avatar = styled.a<PropsAvatar>`
  background: #f5f5f5;
  box-shadow: 0px 22px 44px rgba(0, 0, 0, 0.0908345);
  height: 40px;
  width: 40px;
  border-radius: 100px;
  overflow: hidden;
  top: -80px;
  display: flex;
  align-items: center;
  margin: auto;
  outline: none;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    ${({ avatar }) => !avatar && AvatarWithoutImage}
    margin-left: auto;
    margin-right: auto;
  }
`;

const Basket = styled.a`
  display: inline-flex;
  align-items: center;
  line-height: 1.3;
  padding: 0 10px;
  ${opacityHover};

  .icon,
  .wrap,
  .price,
  .total {
    display: block;
  }

  .icon {
    height: 36px;
    width: 30px;
    background: url("/static/icons/cart.svg") no-repeat center;
  }

  .wrap {
    margin-left: 10px;
  }

  .price {
    font-size: 14px;
  }

  .total {
    font-size: 12px;
    opacity: 0.5;
  }

  @media (max-width: 980px) {
    .wrap {
      display: none;
    }
  }

  @media (max-width: 800px) {
    .icon {
      background-size: 26px;
    }
  }
`;

export default Header;
