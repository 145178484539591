const { createUploadLink } = require("apollo-upload-client");
const { ApolloClient } = require("apollo-client");
const { InMemoryCache } = require("apollo-cache-inmemory");
const { onError } = require("apollo-link-error");
const { ApolloLink } = require("apollo-link");
const fetch = require("isomorphic-unfetch");
const Sentry = require("@sentry/browser");
const { destroyCookie } = require("nookies");

function create(initialState, { getToken, getCity }) {
  const isBrowser = typeof window !== "undefined";
  let token = getToken();

  let cityToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaG9wSWQiOiJjanoxOHBoYW4wMDV3MDc2OXVhdTM0cG9mIn0.e1fREfFnOVvKrEHyvPhvA3mfsEeDxIWQ5Tyn_PKYiQg";

  const httpLink = new createUploadLink({
    uri: process.env.NEXT_PUBLIC_API_ENDPOINT,
    fetch: !isBrowser ? fetch : undefined,
    headers: {
      "x-web": "y",
      "x-stigma-storefront-access-token": cityToken,
      "X-Stigma-Storefront-Customer-Access-Token": token ? token : "",
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    console.info("name:", operation.operationName);
    console.info("variables:", operation.variables);

    Sentry.addBreadcrumb({
      category: "graphql",
      data: {
        name: operation.operationName,
        variables: JSON.stringify(operation.variables, null, 2),
      },
      level: Sentry.Severity.Info,
    });

    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        try {
          if (window && message.indexOf("jwt expired") >= 0) {
            destroyCookie({}, "token");
            window.location.replace("/");
          }
        } catch (error) {}
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    if (networkError) console.warn(`[Network error]: ${networkError}`);
  });

  const link = ApolloLink.from([errorLink, httpLink]);

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,
    link,
    cache: new InMemoryCache().restore(initialState || {}),
  });
}

module.exports = create;
